import {Animate, Banner, Button, Divider, ErrorReporter, Flex, Hidden, Loadable, Navigator, SnackbarService, String, withRegistration} from "@hps/hops-react";
import {CheckoutBasketItem, OrderableTypes, PaymentStatuses, PriceUtils} from "@hps/hops-sdk-js";
import moment from "moment";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import Permissions from "App/Permissions.js";
import ScanBox from "Components/ScanBox.js";
import withAuthUser from "Hoc/withAuthUser.js";
import withOrders from "Hoc/withOrders.js";
import useGateway from "Hooks/useGateway.js";
import SeatReservationEditorDialog from "SeatReservation/SeatReservationEditorDialog.js";
import OrderService from "Services/OrderService.js";
import OrderStationeryService from "Stationery/OrderStationeryService.js";
import {isPrintable} from "Utils/OrderableTypesUtils";

import OrderDetailsViewItemsTable from "./OrderDetailsViewItemsTable.js";
import OrderDetailsViewOrderTable from "./OrderDetailsViewOrderTable.js";
import OrderDetailsViewPanel from "./OrderDetailsViewPanel.js";
import OrderDetailsViewPaymentsTable from "./OrderDetailsViewPaymentsTable.js";
import OrderDetailsViewPurchaserTable from "./OrderDetailsViewPurchaserTable.js";
import PrintTicketsConfirmationDialog from "./PrintTicketsConfirmationDialog.js";
import RefundItemQtyDialog from "./RefundItemQtyDialog.js";
import RePrintReceiptDialog from "./RePrintReceiptDialog.js";

import SettleMoney from "@mui/icons-material/Paid";
import PrintIcon from "@mui/icons-material/Print";
import ReceiptIcon from "@mui/icons-material/Receipt";

import scss from "./OrderDetailsView.module.scss";

const OrderDetailsView = props => {

	const {
		Registration
	} = props;

	const gateway = useGateway();

	const {OrderId, ItemId} = useParams();

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [orderData, setOrderData] = useState(null);

	const [customerReceiptDialogOpen, setCustomerReceiptDialogOpen] = useState(false);
	const [seatReservationDialogOpen, setSeatReservationDialogOpen] = useState(false);
	const [seatReservationDialogActiveItem, setSeatReservationDialogActiveItem] = useState(null);
	const [refundItemQtyDialogOpen, setRefundItemQtyDialogOpen] = useState(false);
	const [refundItemQtyDialogActiveItem, setRefundItemQtyDialogActiveItem] = useState(null);


	/*
	 * Construct a CheckoutBasketItem from the order data. This is required to
	 * use client side processes such as Ticket and Receipt (re)printing.
	 * 
	 * For some reason items in the recall API has slightly different
	 * property names for some orderables, so we have to do this remapping.
	 * 
	 * For future generations: It would be nice if we could just pass
	 * item.Orderable as CheckoutBasketItem.Item without having to do
	 * this transformation.
	 */
	const constructCheckoutBasketItem = item => {

		// Temporary fix for API endpoint returning a single discount code as object {...} instead of array [{...}]

		const iDiscounts = Array.isArray(item.Discounts) ? item.Discounts : [item.Discounts["1"]];
		const totalDiscounts = iDiscounts.reduce((a, b) => (a + (b.DiscountAmount || 0)), 0);
		const originalPrice = (item.Price + totalDiscounts);

		// Pre-populate with all the properties of the orderable
		let itemData = {...item.Orderable};

		switch (item.OrderableType) {

			// Products
			case OrderableTypes.Addon:
				itemData = {
					...itemData,
					Name: item.Description
				};
				break;

			case OrderableTypes.Membership:
				itemData = {
					...itemData,
					Description: item.Description
				};
				break;

			// Travel Tickets
			case OrderableTypes.TicketTravel:
				itemData = {
					...itemData,
					Journeys: item.Orderable?.Journeys?.map(journey => ({
						Arrival: journey.ArrivalTrainSchedule,
						Departure: journey.DepartureTrainSchedule,
						Train: journey.Train
					})),
					TicketOption: {
						...item.Orderable.TicketOption,
						Fares: [item.Orderable.TicketOption.Fare]
					}
				};
				break;

			// Session Tickets
			case OrderableTypes.TicketSession:
				itemData = {
					...itemData,
					Session: item.Orderable.TicketSession,
					FareId: null,
					FareType: item.Orderable.FareType,
					TicketType: item.Orderable.TicketType
				};
				break;

			// Seat Reservations
			case OrderableTypes.SeatReservation:
				itemData = {
					...itemData,
					Train: item.Orderable.TrainAsset
				};
				break;

			// Shares
			case OrderableTypes.Shares:
				itemData = {
					...itemData,
					ShareTypeLabel: `${item.Description} Shares`
				};
				break;

			// System
			case OrderableTypes.System:
				itemData = {
					...itemData,
					Description: item.Description
				};
				break;

			default:
		}

		// Return a CheckoutBasketItem
		const cbi = CheckoutBasketItem.construct({
			Uuid: item.Uuid,
			Item: itemData,
			OrderableType: item.OrderableType,
			Price: originalPrice,
			Quantity: item.Quantity,
			RelatedItemUuid: item.RelatedItemUuid,
			VatProportion: item.VatProportion,
			VatRate: item.VatRate,
			StationeryTemplateId: item.Orderable?.StationeryTemplateId || null
		});

		return cbi;

	};

	const orderTotals = useMemo(() => {

		if (orderData) {

			const SaleValue = orderData.Items.reduce((a, b) => {
				return (a + (b.Price * b.Quantity));
			}, 0);

			const PaymentsThisOrder = orderData.Payments.filter(p => {
				return (p.Order === orderData.Id);
			});

			const PaymentsComplete = PaymentsThisOrder.filter(p => {
				return (p.Status.Id === PaymentStatuses.Complete);
			}).reduce((a, b) => (a + b.PaidAmount), 0);

			const PaymentsPending = PaymentsThisOrder.filter(p => {
				return (p.Status.Id === PaymentStatuses.Pending);
			}).reduce((a, b) => (a + b.PaidAmount), 0);

			return {
				AmountOwing: (SaleValue - PaymentsComplete - PaymentsPending),
				SaleValue,
				PaymentsComplete,
				PaymentsPending
			};

		}
		else return undefined;

	}, [orderData]);

	/**
	 * Transform the OrderData in to something resembling a checkout outcome, for re-printing the receipt.
	 */
	const orderOutcomeData = useMemo(() => {

		if (orderData) {

			const allDiscounts = orderData.Items.reduce((discounts, item) => {

				// Fix for sometimes discounts are an array, sometimes an object
				if (Array.isArray(item.Discounts)) {

					item.Discounts.forEach(discount => {

						discounts.push({
							...discount,
							ItemClaim: item.Uuid,
							DiscountQty: item.Quantity,
							Discount: {
								Amount: discount.DiscountAmount,
								Code: discount.DiscountCode
							}
						});

					});

				}
				else {

					discounts.push({
						...item.Discounts["1"],
						ItemClaim: item.Uuid,
						DiscountQty: item.Quantity,
						Discount: {
							Amount: item.Discounts["1"].DiscountAmount,
							Code: item.Discounts["1"].DiscountCode
						}
					});

				}

				return discounts;

			}, []);

			const PurchasedOrderVouchers = [];

			orderData.Items.filter(i => i.OrderableType === OrderableTypes.VoucherSale).forEach(item => {
				PurchasedOrderVouchers[item.Uuid] = item.Orderable.Vouchers.map(voucher => ({
					...voucher,
					Balance: voucher.IssuedBalance
				}));
			});

			return {
				Order: orderData.Id,
				PosDevice: {
					Id: props.Registration?.Device?.Id,
					Name: props.Registration?.Device?.Name,
					Offline: false
				},
				OrderTimestamp: new moment(orderData.Timestamp).unix(),
				BasketItems: orderData.Items.map(item => constructCheckoutBasketItem(item)),
				CheckoutData: {
					PurchasedOrderVouchers
				},
				Discounts: allDiscounts,
				Payments: orderData.Payments.map(payment => ({
					PaymentType: payment.Type.Id,
					TenderedAmount: payment.PaidAmount
				})),
				PaymentsChangeIsDue: false // Change isn't stored with the order data
			};

		}
		else return null;

	}, [orderData, props.Registration.Device]);

	const isRefundOwing = (orderTotals?.AmountOwing < 0);
	const canRefund = isRefundOwing && props.hasPermission(Permissions.P524_RECALL_REFUND_IN_POS) && (orderData?.TaxDate === null);

	const isPaymentOwing = (orderTotals?.AmountOwing > 0);
	const canTakePayment = isPaymentOwing && (orderData?.TaxDate === null);

	// List of items in this order that can be printed
	const ticketsForPrint = orderData?.Items?.filter(
		item => item.Quantity > 0 && isPrintable(item.OrderableType)
	);

	// List of items in this order that have already been printed
	const ticketsForReprint = ticketsForPrint?.filter(
		item => item.StationeryPrints.some(s => s.Printed === true)
	);

	const printAllTicketsLabel = ticketsForReprint?.length ? "Re-Print All Tickets" : "Print All Tickets";

	// A temporary Print Queue for any items selected for printing
	const [printTicketLines, setPrintTicketLines] = useState([]);

	// Show or hide the print tickets confirmation dialog
	const [printTicketsConfirmationDialogOpen, setPrintTicketsConfirmationDialogOpen] = useState(false);


	/**
	 * Get the order details for a given orderNo
	 *
	 * @async
	 * @return {void}
	 */
	const getOrderDetails = useCallback(async (orderNo, itemNo) => {

		setError(null);
		setLoading(true);

		if (orderNo && parseInt(orderNo) !== orderData?.Id) {
			setOrderData(null);
		}

		try {

			if (orderNo) await OrderService.getOrder(orderNo).then(data => setOrderData(data));
			if (itemNo) await OrderService.getOrderByItem(itemNo).then(data => setOrderData(data));

		}
		catch (e) {
			setError(e);
		}

		setLoading(false);

	}, [orderData]);


	/**
	 * Refresh the order data by Order ID, or Item ID
	 */
	const getOrderData = () => {
		if (OrderId) getOrderDetails(OrderId, null);
		if (ItemId) getOrderDetails(null, ItemId);
	};


	/**
	 * If there are variables set in the route, retrieve the order on load
	 */
	useEffect(() => {
		getOrderData();
	}, [OrderId, ItemId]);


	// Allow closing of the dialog (Submit or Cancel)
	const handlePrintTicketsConfirmationDialogClose = () => setPrintTicketsConfirmationDialogOpen(false);


	// Handle confirmation dialog submit and do the actual printing
	const handlePrintTicketsConfirmationDialogSubmit = reason => {

		printTicketLines.forEach(async item => {
			await printOrderItemTicket(item, reason);
		});

		handlePrintTicketsConfirmationDialogClose();
	};


	/**
	 * Prepare to print all the printable order lines
	 */
	const handlePrintAllTickets = () => {
		setPrintTicketLines(ticketsForPrint);
	};


	/**
	 * Prepare to print one order line
	 */
	const handlePrintOneTicket = item => {
		setPrintTicketLines([item]);
	};


	useEffect(() => {
		if (printTicketLines.length) setPrintTicketsConfirmationDialogOpen(true);
	}, [printTicketLines]);


	/**
	 * Print one Order Item line
	 * 
	 * @param {object} orderItem 
	 */
	const printOrderItemTicket = async (orderItem, invalidatedReason) => {

		// Make a tiny basket of the requested item, and all related items (e.g. Seat Reservations)
		let allRelatedItems = [orderItem];
		allRelatedItems = allRelatedItems.concat(orderData.Items.filter(x => x.RelatedItemUuid === orderItem.Uuid));

		// Create dummy CheckoutBasketItems for everything.
		const basketItems = allRelatedItems.map(item => constructCheckoutBasketItem(item));

		// Will we be printing our stationery?
		let willPrintStationery = false;

		// Check Gateway connectivity to determine whether to print stationery
		try {
			willPrintStationery = await gateway.checkConnectivity();
		}
		catch (e) {
			ErrorReporter.report(e);
		}


		if (willPrintStationery) {

			// Stationery printing data to submit
			const itemBarcodes = OrderStationeryService.createBasketBarcodes(basketItems);

			// Record a Print/Re-Print of the Stationery
			const itemBarcodesReturned = await OrderStationeryService.recordPrintItemStationery(orderData.Id, orderItem.Id, itemBarcodes[orderItem.Uuid], invalidatedReason);

			// Work out which ones actually saved
			const itemBarcodesStored = itemBarcodesReturned?.SavedPrints;

			/**
			 * Carry out stationery operations now
			 * 
			 * Note: We don't want to render the stationery unless
			 * printing is available because this is an explicit printing
			 * request and must succeed. This is different in Checkout where
			 * you still want to store the barcodes even if printing is
			 * unavailable.
			 * 
			 */
			try {

				/**
				 * Render the stationery for our items
				 */
				const renderedStationery = OrderStationeryService.generateBasketItemsStationery(
					basketItems, // dummy item and related basketItems
					[], // basketDiscounts
					(Registration.Stationery || {}), // templates
					orderData.Id, // orderId
					(Date.parse(orderData.Timestamp) / 1000), // orderTimestamp
					Registration.Device.Name, // deviceIdentifier
					{}, // checkoutData
					itemBarcodes, // itemBarcodes
					itemBarcodesStored // itemBarcodesStored
				);

				await OrderStationeryService.printRenderedStationery(renderedStationery);

			}
			catch (e) {
				ErrorReporter.report(e);
			}

			// Reload our Order data with the new Barcodes
			getOrderData();
		}
		else {
			SnackbarService.snack("Connectivity error (check that the Gateway server is running).", "error");
		}

	};


	/**
	 * Closing the refund item quantity dialog.
	 * 
	 * @return {void}
	 */
	const handleCloseRefundItemQtyDialog = useCallback(() => {
		setRefundItemQtyDialogOpen(false);
	}, []);


	/**
	 * Handle refund needs settling
	 * 
	 * @return {void}
	 */
	const handleSettleRefund = useCallback(() => {
		Navigator.navigate(`/search/orders/${orderData.Id}/refund`);
	}, [orderData]);


	/**
	 * Closing the seat reservation dialog.
	 * 
	 * @return {void}
	 */
	const handleCloseSeatReservationDialog = useCallback(() => {
		setSeatReservationDialogOpen(false);
	}, []);


	/**
	 * Starting to edit an item's seat reservations.
	 *
	 * @param {Object} item
	 * @return {void}
	 */
	const handleEditSeats = useCallback(item => {
		setSeatReservationDialogActiveItem(item);
		setSeatReservationDialogOpen(true);
	}, []);


	/**
	 * Starting to edit an item's quantity (only down, for refunds)
	 *
	 * @param {Object} item
	 * @return {void}
	 */
	const handleRefundItem = useCallback(item => {
		setRefundItemQtyDialogActiveItem(item);
		setRefundItemQtyDialogOpen(true);
	}, []);


	/**
	 * Customer receipt dialog.
	 */
	const handleCustomerReceiptClick = () => setCustomerReceiptDialogOpen(true);
	const handleCustomerReceiptDialogClose = () => setCustomerReceiptDialogOpen(false);

	const renderOrder = () => {
		return (
			<>
				<String
					bold={true}
					noFlex={true}
					str={`Sale L${orderData.Id}`}
					variant="h5" />
				<Flex gap={2}>
					<Flex columnar={true} alignItems="center">
						<Hidden hidden={!ticketsForPrint?.length}>
							<Button
								label={printAllTicketsLabel}
								onClick={handlePrintAllTickets}
								startIcon={PrintIcon}
								variant="contained" />
						</Hidden>
						<Button
							label="Customer Receipt"
							onClick={handleCustomerReceiptClick}
							startIcon={ReceiptIcon} />
					</Flex>

					<Hidden hidden={!canRefund}>
						<Banner
							action={<Button
								color="error"
								label="Issue Refund Money to Customer"
								onClick={() => Navigator.navigate(`/search/orders/${orderData.Id}/refund`)}
								startIcon={<Animate animation="pop" component={SettleMoney} />} />}
							str="You must settle the balance of this order by issuing a refund to the customer."
							severity="error"
							title="Customer Owed Refund" />
					</Hidden>
					<Hidden hidden={!canTakePayment}>

						<Banner
							severity="warning"
							str="Additional payments need to be taken through HOPS Retail Systems back office."
							title={`There is an outstanding balance of ${PriceUtils.getDisplayStringIntl(orderTotals?.AmountOwing)} on this order.`} />
					</Hidden>

					<Hidden hidden={!orderData?.TaxDate}>
						<Banner
							str="You can't modify this order, because it has a committed tax date."
							severity="info" />
					</Hidden>

					<Flex
						className={scss.rowContainer}
						columnar={true}
						gap={2}>
						<OrderDetailsViewPanel label="Order Details">
							<OrderDetailsViewOrderTable order={orderData} />
						</OrderDetailsViewPanel>
						<OrderDetailsViewPanel label="Customer Details">
							<OrderDetailsViewPurchaserTable order={orderData} />
						</OrderDetailsViewPanel>
					</Flex>
					<OrderDetailsViewPanel label="Sale Items">
						{(orderData.Items?.length ?
							<OrderDetailsViewItemsTable
								order={orderData}
								onEditSeats={handleEditSeats}
								onPrintLineTickets={handlePrintOneTicket}
								onRefundLineItems={handleRefundItem} /> :
							<String
								color="textSecondary"
								noFlex={true}
								str="No items." />)}
					</OrderDetailsViewPanel>
					<OrderDetailsViewPanel label="Payments">
						{(orderData.Payments?.length ? <OrderDetailsViewPaymentsTable order={orderData} /> : <String color="textSecondary" noFlex={true} str="No payments." />)}
					</OrderDetailsViewPanel>
					<SeatReservationEditorDialog
						onClose={handleCloseSeatReservationDialog}
						onSubmitted={getOrderData}
						open={seatReservationDialogOpen}
						order={orderData}
						orderItem={seatReservationDialogActiveItem?.Uuid} />
					<RefundItemQtyDialog
						onClose={handleCloseRefundItemQtyDialog}
						onSubmitted={handleSettleRefund}
						open={refundItemQtyDialogOpen}
						order={orderData}
						orderItem={refundItemQtyDialogActiveItem?.Uuid} />
				</Flex>
				<PrintTicketsConfirmationDialog
					onClose={handlePrintTicketsConfirmationDialogClose}
					onSubmit={handlePrintTicketsConfirmationDialogSubmit}
					open={printTicketsConfirmationDialogOpen}
					printLines={printTicketLines} />
				<RePrintReceiptDialog
					onClose={handleCustomerReceiptDialogClose}
					open={customerReceiptDialogOpen}
					PaymentOutcome={orderOutcomeData} />
			</>
		);
	};

	/**
	 * Do we have an invalid order number?
	 */
	const is404 = (error?.response?.status === 404);

	return (
		<Flex
			gap={2}
			px={1}
			py={1}>
			<String
				bold={true}
				noFlex={true}
				str="Recall Sale"
				variant="h5" />
			<ScanBox
				label="Order No. / QR Code"
				placeholder="L123456"
				showSubmit={true} />
			<Divider/>
			<Loadable
				FlexProps={{alignItems: "flex-start"}}
				error={!!error}
				errorStr={(is404 ? `Unknown order number.` : `${error}`)}>
				{(
					orderData ?
						renderOrder() :
						(loading ?
							<String
								color="textSecondary"
								noFlex={true}
								str="Loading..." /> :
							"")
				)}
			</Loadable>
		</Flex>
	);

};

export default withAuthUser(withOrders(withRegistration(OrderDetailsView)));
