import {TableList} from "@hps/hops-react";
import {memo} from "react";

const OrderDetailsViewPurchaserTable = memo(({order}) => {

	return (
		<TableList
			data={[
				{
					label: "Contact Name",
					content: `${(order.Purchaser?.Fname || "")} ${(order.Purchaser?.Sname || "")}`.trim()
				},
				{
					label: "Email Address",
					content: `${order.Purchaser?.Email || ""}`
				}
			]} />
	);

});

export default OrderDetailsViewPurchaserTable;
