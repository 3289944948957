import {String, Table} from "@hps/hops-react";
import {PriceUtils} from "@hps/hops-sdk-js";
import {TableCell} from "@mui/material";
import moment from "moment";
import {memo} from "react";

import scss from "./OrderDetailsViewPaymentsTable.module.scss";

const OrderDetailsViewPaymentsTable = memo(({order}) => {

	const tableFields = [
		{
			label: "#",
			render: (i, index) => `${(index + 1)}`
		},
		{
			label: "Created",
			render: i => (new moment(i.Timestamp)).format("DD/MM/YYYY HH:mm:ss")
		},
		{
			label: "Type",
			render: i => i.Type?.Label
		},
		{
			label: "Source",
			render: i => i.Source?.Label
		},
		{
			label: "Remarks",
			render: i => (i.Remarks || <String color="textSecondary" noFlex={true} str="(None)" />)
		},
		{
			label: "Amount",
			render: i => PriceUtils.getDisplayStringIntl(i.PaidAmount)
		},
		{
			label: "Status",
			render(i) {

				const statusId = i.Status?.Id;

				return (
					<String
						// Failed, Error, Confirmed, Declined
						bold={[2, 7, 8, 9].includes(statusId)}
						str={i.Status?.Name}
						style={{
							color: (
								// Confirmed
								(statusId === 8) ?
									"green" :
									(
										// Pending
										(statusId === 1) ?
											"grey" :
											(
												// Failed, Error, Declined
												[2, 7, 9].includes(statusId) ?
													"red" :
													undefined
											)
									)
							)
						}} />
				);

			}
		}
	];

	return (
		<Table
			items={order.Payments}
			fields={tableFields}
			footer={
				<>
					<TableCell
						colSpan={(tableFields.length - 2)} />
					<TableCell
						colSpan={2}
						children={
							<String
								bold={true}
								color="textPrimary"
								noFlex={true}
								str={PriceUtils.getDisplayStringIntl(order.Payments.reduce((a, b) => (a + b.PaidAmount), 0))} />
						} />
				</>
			}
			tableClassName={scss.table} />
	);

});

export default OrderDetailsViewPaymentsTable;
