import {Flex, String} from "@hps/hops-react";
import {memo} from "react";

const OrderDetailsViewPanel = memo(({children, label}) => {

	return (
		<Flex gap={0.5}>
			<String
				str={label}
				noFlex={true}
				variant="h6" />
			{children}
		</Flex>
	);

});

export default OrderDetailsViewPanel;
