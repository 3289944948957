import {Banner, EmptyStateGate, Flex, TabPanel, UnhandledErrorView, withRegistration, withSnackbars} from "@hps/hops-react";
import {ErrorBoundary} from "@sentry/react";
import React from "react";

import ScanBox from "Components/ScanBox.js";
import dInventoryTab from "Dispatchers/dInventoryTab.js";
import withBasket from "Hoc/withBasket.js";
import withReducedFunctionality from "Hoc/withReducedFunctionality.js";
import withUi from "Hoc/withUi.js";
import BasketService from "Services/BasketService.js";

import FavouritesInventoryBrowser from "./Favourites/FavouritesInventoryBrowser.js";
import InventoryNoFeaturesError from "./InventoryNoFeaturesError.js";
import ProductBrowser from "./Products/ProductInventoryBrowser.js";
import TicketSessionInventoryBrowser from "./TicketsSessions/TicketSessionInventoryBrowser.js";
import TicketTravelInventoryBrowser from "./TicketsTravel/TicketTravelInventoryBrowser.js";
import VoucherBrowser from "./Vouchers/VoucherInventoryBrowser.js";

import FavouritesIcon from "@mui/icons-material/Star";

import scss from "./InventoryBrowser.module.scss";


/**
 * Inventory browser
 * 
 * Renders all available inventory panels as a tabbed layout.
 * 
 * @package HOPS
 * @subpackage Inventory
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const InventoryBrowser = props => {

	const TabPanelFlexProps = {className: scss.root};

	const tabs = [
		{
			label: "Travel",
			hidden: !props.hasRegistrationFeature("Tickets"),
			component: TicketTravelInventoryBrowser,
			componentProps: {onAddToBasket: BasketService.addItems}
		},
		{
			label: "Events",
			hidden: !props.hasRegistrationFeature("Tickets"),
			component: TicketSessionInventoryBrowser,
			componentProps: {onAddToBasket: BasketService.addItems}
		},
		{
			label: "Products",
			hidden: !props.hasRegistrationFeature("OrgProducts"),
			component: ProductBrowser,
			componentProps: {onAddToBasket: BasketService.addItems}
		},
		{
			label: "Vouchers",
			hidden: !props.hasRegistrationFeature("VoucherSales") || props.AlwaysOffline,
			component: VoucherBrowser,
			componentProps: {onAddToBasket: BasketService.addItems}
		},
		{
			icon: FavouritesIcon,
			component: FavouritesInventoryBrowser,
			hidden: !props.hasRegistrationFeature("Tickets")
		}
	].filter(t => !t.hidden);

	const activeTab = Math.min(Math.max(props.Ui.InventoryTab, 0), tabs.length);

	const renderBrowserWarning = () => {
		return (
			<Flex gap={0}>
				<Banner title="Check your Web Browser Version" severity="error" str="HOPS Point of Sale only supports the 3 most recent versions. Please ensure your device's web browser is fully updated. " />
				<UnhandledErrorView/>
			</Flex>
		);
	};

	if (!tabs.length) {
		return (
			<Flex px={1} py={1}>
				<EmptyStateGate isEmpty={true} />
			</Flex>
		);
	}

	return <>
		<ErrorBoundary fallback={renderBrowserWarning}>
			<TabPanel
				FlexProps={TabPanelFlexProps}
				gap={1}
				noTabsState={InventoryNoFeaturesError}
				onChange={dInventoryTab}
				preContent={<ScanBox variant="filled" />}
				tabs={tabs}
				scrollButtons="auto"
				value={activeTab}
				variant="scrollable" />
		</ErrorBoundary>
	</>;

};

export default withBasket(withReducedFunctionality(withRegistration(withSnackbars(withUi(InventoryBrowser)))));
