import {Div, Flex, Hidden, IconButton, String, Table} from "@hps/hops-react";
import {DeliveryMethods, OrderableTypes, PriceUtils, TicketSeatReservationModes, UiLabel} from "@hps/hops-sdk-js";
import {TableCell} from "@mui/material";
import {memo, useEffect, useState} from "react";

import Permissions from "App/Permissions";
import withAuthUser from "Hoc/withAuthUser";
import {allowsRefund, isPrintable} from "Utils/OrderableTypesUtils";

import RefundIcon from "@mui/icons-material/CurrencyExchange";
import SeatingIcon from "@mui/icons-material/EventSeatOutlined";
import PrintIcon from "@mui/icons-material/Print";

import scss from "./OrderDetailsViewItemsTable.module.scss";


const OrderDetailsViewItemsTable = memo(({hasPermission, order, onEditSeats, onPrintLineTickets, onRefundLineItems}) => {

	const getItemPriceWithVat = i => (i.Price);

	const canRefund = hasPermission(Permissions.P524_RECALL_REFUND_IN_POS) && (order.TaxDate === null);

	const [sortedOrderItems, setSortedOrderItems] = useState([]);
	const [totalPrice, setTotalPrice] = useState(0);


	/**
	 * A hook to sort the order items so children appear directly below their parent
	 */
	useEffect(() => {

		// Filter all root items that don't have a parent
		const parentItems = order.Items.filter(o => o.RelatedItemUuid === null);

		let sortedItems = [];

		parentItems.forEach(item => {
			sortedItems.push(item); // Add the parent
			sortedItems = sortedItems.concat(order.Items.filter(o => o.RelatedItemUuid === item.Uuid)); // Add the parent's children
		});

		// Set the sorted list state
		setSortedOrderItems(sortedItems);

		// Calculate the order total
		setTotalPrice(order.Items.reduce((a, b) => ((a + getItemPriceWithVat(b) * b.Quantity)), 0));

	}, [order.Items]);


	const renderOrderItemStationery = item => {

		const stationeryFields = [
			{
				label: "Ticket Barcode",
				render: s => s.Barcode
			},
			{
				label: "Status",
				render(s) {

					// Must come last so it overwrites
					if (s.Invalidated) {
						return (
							<Flex gap={0}>
								<String
									component="p"
									color="error"
									bold={true}
									noFlex={true}
									str="Invalidated"
									variant="caption" />
								<String
									component="p"
									color="error"
									noFlex={true}
									str={s.InvalidatedReason}
									variant="caption" />
							</Flex>
						);
					}

					if (s.Printed) {
						return (<String
							component="p"
							bold={true}
							noFlex={true}
							str="Printed" />);
					}

					if (!(s.Invalidated && s.Printed)) {
						return (<String
							component="p"
							bold={true}
							noFlex={true}
							str="Not printed" />);
					}

					return "Unknown";

				}
			}
		];

		// Must be a printable item
		if (!isPrintable(item.OrderableType)) return <></>;

		if (!item.StationeryPrints.length) {
			return (<String
				component="p"
				color="error"
				bold={true}
				noFlex={true}
				str="Stationery not yet printed"
				variant="caption" />);
		}

		return <Table
			fields={stationeryFields}
			items={item.StationeryPrints}
			tableClassName={scss.tabletiny} />;

	};

	const tableFields = [
		{
			label: "Id",
			render: i => (
				<>
					<String noFlex={true} str={i.Id} />
					{(!!i.QuantityRemoved && <String color="error" noFlex={true} str={`(${i.QuantityRemoved} removed)`} variant="caption" />)}
				</>
			)
		},
		{
			label: "Qty",
			render: i => (
				<>
					<String noFlex={true} str={`${i.Quantity}x`} />
					{(!!i.QuantityRemoved && <String color="error" noFlex={true} str={`(${i.QuantityRemoved} removed)`} variant="caption" />)}
				</>
			)
		},
		{
			label: "Type",
			render(i) {
				let typeLabel = UiLabel.OrderableTypes(i.OrderableType);
				if (i.OrderableType === OrderableTypes.System) typeLabel = UiLabel.SystemItemTypes(i.Orderable?.Id);
				return <>
					<String noFlex={true} str={typeLabel} />
					{i.RelatedItemUuid && <String color="primary" noFlex={true} str={`For line ${sortedOrderItems.find(x => x.Uuid === i.RelatedItemUuid)?.Id}`} variant="subtitle2" />}
				</>;
			}
		},
		{
			label: "Description",
			render(i) {
				return (
					<Flex gap={0.5}>
						<Div>
							<String
								noFlex={true}
								str={i.Description} />
						</Div>

						<Hidden hidden={!i.DeliveryMethod}>
							<String
								component="p"
								color="error"
								bold={true}
								noFlex={true}
								str={DeliveryMethods.getOrderableName(i.DeliveryMethod)}
								variant="caption" />
						</Hidden>

						{renderOrderItemStationery(i)}
					</Flex>
				);
			}
		},
		{
			label: "Price",
			render(i) {

				// Temporary fix for API endpoint returning a single discount code as object {...} instead of array [{...}]
				const iDiscounts = Array.isArray(i.Discounts) ? i.Discounts : [i.Discounts["1"]];

				const finalDiscountedPrice = getItemPriceWithVat(i);
				const totalDiscount = iDiscounts.reduce((a, b) => (a + b.DiscountAmount), 0);
				const originalPrice = (finalDiscountedPrice + totalDiscount);

				return (
					<>
						<String
							noFlex={true}
							str={PriceUtils.getDisplayStringIntl(finalDiscountedPrice)} />
						{
							!!iDiscounts?.length &&
							<String bold={true} noFlex={true} str={`Original Price: ${PriceUtils.getDisplayStringIntl(originalPrice)}`} variant="caption" />
						}
						{
							!!iDiscounts?.length &&
							iDiscounts.map((discount, key) => {

								const discountEffectLabel = (
									discount.PercentageDiscount ?
										`${discount.PercentageDiscount}%` :
										PriceUtils.getDisplayStringIntl(discount.AbsoluteDiscount)
								);

								return (
									<String
										color="error"
										component="p"
										bold={true}
										key={key}
										noFlex={true}
										str={`Discount: ${discount.DiscountCode} (-${discountEffectLabel})`}
										variant="caption" />
								);
							})
						}
					</>
				);

			}
		},
		{
			label: "Total",
			render: i => PriceUtils.getDisplayStringIntl((getItemPriceWithVat(i) * i.Quantity))
		},
		{
			label: "Actions",
			render(i) {
				return (
					<Flex
						columnar={true}
						justifyContent="space-evenly"
						gap={0}>
						<Hidden hidden={!((i.Quantity > 0) && (TicketSeatReservationModes.allowsCustomerSelection(i.Orderable.SeatReservationMode) || TicketSeatReservationModes.allowsRailwaySelection(i.Orderable.SeatReservationMode)))}>
							<IconButton
								icon={SeatingIcon}
								onClick={() => onEditSeats?.(i)}
								tooltip="Edit Seats" />
						</Hidden>

						<Hidden hidden={!((i.Quantity > 0) && (isPrintable(i.Orderable.Type)))}>
							<IconButton
								icon={PrintIcon}
								onClick={() => onPrintLineTickets?.(i)}
								tooltip="Print Line Stationery" />
						</Hidden>

						<Hidden hidden={!((i.Quantity > 0) && (allowsRefund(i.Orderable.Type)))}>
							<IconButton
								disabled={!canRefund}
								icon={RefundIcon}
								onClick={() => onRefundLineItems?.(i)}
								tooltip="Refund Item(s)" />
						</Hidden>
					</Flex>
				);
			}
		}
	];

	return (
		<Table
			items={sortedOrderItems}
			fields={tableFields}
			footer={
				<>
					<TableCell
						colSpan={(tableFields.length - 2)} />
					<TableCell
						colSpan={2}
						children={
							<String
								bold={true}
								color="textPrimary"
								noFlex={true}
								str={PriceUtils.getDisplayStringIntl(totalPrice)} />
						} />
				</>
			}
			tableClassName={scss.table} />
	);

});

export default withAuthUser(OrderDetailsViewItemsTable);
