import {String, TableList} from "@hps/hops-react";
import {PaymentStatuses, PriceUtils} from "@hps/hops-sdk-js";
import moment from "moment";
import {memo, useMemo} from "react";

const OrderDetailsViewOrderTable = memo(({order}) => {

	const orderStatusId = order.Status?.Id;

	const orderTotals = useMemo(() => {

		if (order) {

			const SaleValue = order.Items.reduce((a, b) => {
				return (a + ((b.Price) * b.Quantity));
			}, 0);

			const PaymentsThisOrder = order.Payments.filter(p => {
				return (p.Order === order.Id);
			});

			const PaymentsComplete = PaymentsThisOrder.filter(p => {
				return (p.Status.Id === PaymentStatuses.Complete);
			}).reduce((a, b) => (a + b.PaidAmount), 0);

			const PaymentsPending = PaymentsThisOrder.filter(p => {
				return (p.Status.Id === PaymentStatuses.Pending);
			}).reduce((a, b) => (a + b.PaidAmount), 0);

			return {
				AmountOwing: (SaleValue - PaymentsComplete - PaymentsPending),
				SaleValue,
				PaymentsComplete,
				PaymentsPending
			};

		}
		else return undefined;

	}, [order]);

	const amountOwing = (orderTotals?.AmountOwing !== 0);
	// const refundAmountOwing = ((orderTotals?.AmountOwing < 0) ? Math.abs(orderTotals?.AmountOwing) : 0);

	return (
		<TableList
			data={[
				{
					label: "Order No.",
					content: `L${order.Id}`
				},
				{
					label: "Order Value",
					content: PriceUtils.getDisplayStringIntl(orderTotals.SaleValue)
				},
				{
					label: "Order Status",
					content: (
						<String
							bold={[0, 2, 4].includes(orderStatusId)}
							noFlex={true}
							str={order.Status?.Label}
							style={{
								color: (
									// Confirmed
									(orderStatusId === 2) ?
										"green" :
										(
											// Provisional
											(orderStatusId === 1) ?
												"grey" :
												// Rejected, Cancelled
												(
													[0, 4].includes(orderStatusId) ?
														"red" :
														undefined
												)
										)
								)
							}} />
					)
				},
				{
					label: "Amount Owing",
					content: (
						<String
							bold={true}
							color={(amountOwing ? "error" : undefined)}
							str={PriceUtils.getDisplayStringIntl(orderTotals?.AmountOwing)}
							style={{color: amountOwing ? undefined : "green"}} />
					)
				},
				{
					label: "Source",
					content: order.Source?.Label
				},
				{
					label: "Creation Time",
					content: (new moment(order.Timestamp)).format("DD/MM/YYYY HH:mm:ss")
				},
				{
					label: "Tax Date",
					content: order.TaxDate ? (new moment(order.Timestamp)).format("DD/MM/YYYY HH:mm:ss") : "Uncommitted"
				}
			]} />
	);

});

export default OrderDetailsViewOrderTable;
