import {Button, Flex, Hidden, SnackbarService, String} from "@hps/hops-react";
import {CheckoutBasketItem, OrderableTypes, PaymentTypes, PriceUtils} from "@hps/hops-sdk-js";
import PropTypes from "prop-types";
import React, {useState} from "react";

import ConfirmationDialog from "Components/ConfirmationDialog";
import withBasket from "Hoc/withBasket";
import withInventory from "Hoc/withInventory";
import withReducedFunctionality from "Hoc/withReducedFunctionality";
import BasketService from "Services/BasketService.js";

import HOPSAppIcon from "@mui/icons-material/AppShortcut";
import GiftCardIcon from "@mui/icons-material/CardGiftcard";
import CardIcon from "@mui/icons-material/CreditCard";
import SubTotalIcon from "@mui/icons-material/Functions";
import CashIcon from "@mui/icons-material/Money";

import scss from "./PaymentMethodButtons.module.scss";

/**
 * Renders the payment method buttons that point to Payment Handlers
 */
const PaymentMethodButtons = props => {

	const [voucherDialogOpen, setVoucherDialogOpen] = useState(false);
	const [disabled, setDisabled] = useState(false);

	// Attempt to find a suitable voucher product to use
	const voucher = props.Inventory?.Vouchers?.find(v => v.BalanceToIssue === null && v.ExpiryMonths === 12);

	const handleVoucherDialogSuccess = async () => {

		setDisabled(true);

		try {
			await BasketService.addItems([
				CheckoutBasketItem.construct({
					OrderableType: OrderableTypes.VoucherSale,
					Item: voucher,
					Price: props.BasketTotal * -1,
					Quantity: 1,
					VatProportion: 0,
					VatRate: 0,
					StationeryTemplateId: voucher.Stationery
				})
			]);
		}
		catch (e) {
			SnackbarService.snack(e);
		}

		setDisabled(false);

	};

	const handleVoucherDialogClose = () => {
		setVoucherDialogOpen(false);
	};


	const renderPaymentMethods = () => {

		return (
			<Flex gap={0.5}>
				<Flex
					justifyContent="space-between"
					columnar={props.columnar}
					gap={0.5}>
					{!props.AlwaysOffline && <Button
						className={scss.voucherButton}
						disabled={props.disabled || props.ReducedFunctionality}
						label="Voucher"
						onClick={props.handleChangePaymentType}
						size="large"
						startIcon={GiftCardIcon}
						value={PaymentTypes.Voucher} />}

					<Hidden hidden={!(props.BasketIdentity.OfflineBasketUuid)}>
						<Button
							disabled={props.disabled}
							label={"App"}
							onClick={props.handleChangePaymentType}
							size="large"
							startIcon={HOPSAppIcon}
							value={PaymentTypes.Placeholder} />
					</Hidden>

					<Hidden hidden={!props.additionalCheckoutBtn}>
						<Button
							className={scss.additionalCheckoutBtn}
							label="..."
							onClick={props.handleChangePaymentType}
							value={null} />
					</Hidden>
				</Flex>
				<Flex
					className={props.columnar && scss.containerCheckoutButtons}
					justifyContent="space-between"
					columnar={props.columnar}
					gap={0.5}>
					<Button
						disabled={props.disabled}
						label="Card"
						onClick={props.handleChangePaymentType}
						size="large"
						startIcon={CardIcon}
						value={PaymentTypes.CardHolderPresent}
						variant="contained" />
					<Button
						disabled={props.disabled}
						label="Cash"
						onClick={props.handleChangePaymentType}
						size="large"
						startIcon={CashIcon}
						value={PaymentTypes.Cash}
						variant="contained" />
					{props.AlwaysOffline && <Hidden hidden={!props.additionalCheckoutBtn}>
						<Button
							className={scss.additionalCheckoutBtn}
							label="..."
							onClick={props.handleChangePaymentType}
							value={null} />
					</Hidden>}
				</Flex>
			</Flex>
		);

	};


	const renderRefundMethods = () => {

		return (
			<>
				<Flex gap={0.5}>
					<Flex>
						<String
							bold={true}
							str="Customer Refund"
							variant="h6" />
					</Flex>
					<Flex
						justifyContent="space-between"
						columnar={props.columnar}
						gap={0.5}>
						<Button
							className={scss.voucherButton}
							disabled={props.disabled || props.ReducedFunctionality}
							label="As Voucher"
							onClick={setVoucherDialogOpen}
							size="large"
							startIcon={GiftCardIcon}
							value={true} />
						<Hidden hidden={!props.additionalCheckoutBtn}>
							<Button
								className={scss.additionalCheckoutBtn}
								label="..."
								onClick={props.handleChangePaymentType}
								value={null} />
						</Hidden>
					</Flex>
					<Flex
						className={props.columnar && scss.containerCheckoutButtons}
						justifyContent="space-between"
						columnar={props.columnar}
						gap={0.5}>
						<Button
							disabled={props.disabled}
							label="To Card"
							onClick={props.handleChangePaymentType}
							size="large"
							startIcon={CardIcon}
							value={PaymentTypes.CardHolderPresent}
							variant="contained" />
						<Button
							disabled={props.disabled}
							label="As Cash"
							onClick={props.handleChangePaymentType}
							size="large"
							startIcon={CashIcon}
							value={PaymentTypes.Cash}
							variant="contained" />
					</Flex>
				</Flex>
				<ConfirmationDialog
					disabled={disabled}
					noCloseButton={!!(voucher)}
					noOkButton={!!(voucher)}
					onClose={handleVoucherDialogClose}
					onSuccess={handleVoucherDialogSuccess}
					open={voucherDialogOpen}
					message={[`Add a ${PriceUtils.getDisplayStringIntl(props.BasketTotal * -1)} Voucher to the basket to settle the refund.`]}
					title="Refund to Voucher" />
			</>
		);

	};


	if (!props.PaymentsIsSettled && props.BasketTotal > 0) {

		// We have a payment to collect
		return renderPaymentMethods();
	}
	else if (!props.PaymentsIsSettled && props.BasketTotal < 0) {

		// We have a refund to issue
		return renderRefundMethods();
	}
	else {

		// Order Balance is settled
		return (
			<Button
				className={scss.confirmSaleButton}
				disabled={props.disabled}
				label="Sub Total"
				onClick={props.handleChangePaymentType}
				size="large"
				startIcon={SubTotalIcon}
				variant="contained"
				value={null} />
		);
	}
};

PaymentMethodButtons.propTypes = {
	additionalCheckoutBtn: PropTypes.bool,
	columnar: PropTypes.bool,
	disabled: PropTypes.bool,
	handleChangePaymentType: PropTypes.func
};

PaymentMethodButtons.defaultProps = {
	additionalCheckoutBtn: true,
	columnar: true,
	disabled: false
};

export default withBasket(withInventory(withReducedFunctionality(PaymentMethodButtons)));
